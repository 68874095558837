import axios from 'axios';
import { siteInfo } from '@/store/siteInfo';
import {
  connectionTypeJWT,
  customerCodeJWT, locationIdJWT, macJWT, nasIdJWT, isExpiredJWT, isAnonymousJWT,
} from '@/utils/DecodeJWT';
import { SnackbarAction } from '@/store/snackbar';
import { SnackbarType } from '@/models/enums/snackbar';
import { GET_STARDUST_TOKEN } from '@/store/stardustSession';
import { ACTION_BLANK_SESSION } from '@/store/global';
import { getStripeScript } from '@/utils/stripeScript';

export const ACTION_SET_STARDUST_API = 'api/setStardust';
const DEFAULT_STARDUST_API_BASE = '/api';
const DEFAULT_ERROR_MESSAGE = 'Something went wrong.';

export const api = {
  namespaced: true,
  state: () => ({
    old: axios.create({
      baseURL: '',
    }),
    stardust: null,
    stripeScript: null,
    stripeSessionId: null,
    stripeSessionStatus: null,
  }),
  mutations: {
    upsertStripeScript(state, { sessionId = null, publishableKey }) {
      state.stripeScript = Stripe(publishableKey);
      localStorage.setItem('stripeSessionId', sessionId);
      state.stripeSessionId = sessionId;
    },
    upsertStardustApi(state, stardustApi) {
      state.stardust = stardustApi;
    },
    upsertStripeSessionStatus(state, sessionStatus) {
      state.stripeSessionStatus = sessionStatus;
    },
  },
  actions: {
    async setStardust({ dispatch, commit, rootState }) {
      const stardustApiBase = rootState.global.publicConfig.STARDUST_API_BASE ? rootState.global.publicConfig.STARDUST_API_BASE : DEFAULT_STARDUST_API_BASE;
      const genericErrorMessage = rootState.global.publicConfig.GENERIC_ERROR_MESSAGE ? rootState.global.publicConfig.GENERIC_ERROR_MESSAGE : DEFAULT_ERROR_MESSAGE;

      const instance = axios.create({
        baseURL: stardustApiBase,
      });
      const token = await dispatch(GET_STARDUST_TOKEN, null, { root: true });
      if (token) {
        instance.defaults.headers.common.Authorization = `Bearer ${token}`;
      }
      instance.interceptors.request.use(async (config) => {
        if (isExpiredJWT() && isAnonymousJWT()) {
          await dispatch(ACTION_BLANK_SESSION, {
            mac: macJWT(),
            nas_id: nasIdJWT(),
            location_id: locationIdJWT(),
            customer_code: customerCodeJWT(),
            connection_type: connectionTypeJWT(),
          }, { root: true });
        }
        return config;
      }, error => Promise.reject(error));
      instance.interceptors.response.use(undefined, async (error) => {
        if (!error.response || !error.response.status || error.response.status === 502) {
          dispatch(SnackbarAction.Open, {
            text: genericErrorMessage,
            type: SnackbarType.Error,
            timeout: 5000,
          }, { root: true });
          return Promise.reject(error);
        }
        const errorStatus = error.response.status;
        const sessionToken = await dispatch(GET_STARDUST_TOKEN, null, { root: true });
        if (errorStatus === 401 && sessionToken && !siteInfo.state.isLoggedIn) {
          await dispatch(ACTION_BLANK_SESSION, {
            mac: macJWT(),
            nas_id: nasIdJWT(),
            location_id: locationIdJWT(),
            customer_code: customerCodeJWT(),
            connection_type: connectionTypeJWT(),
          }, { root: true });
          return Promise.reject(error);
        }

        if (errorStatus === 401 && sessionToken && siteInfo.state.isLoggedIn) {
          location.hash = '/login';
          return Promise.reject(error);
        }
        if (errorStatus === 403) {
          const errorMessage = error.response.data.message;
          dispatch(SnackbarAction.Open, {
            text: errorMessage,
            type: SnackbarType.Error,
            timeout: 5000,
          }, { root: true });
          return Promise.reject(error);
        }
        return Promise.reject(error);
      });

      await commit('upsertStardustApi', instance);
      return Promise.resolve();
    },
    httpPapNoOp({ state }) {
      return state.stardust.get('/http-pap/no-op')
        .then(response => response.data);
    },

    fetchStripeContext({ state, commit }, payload) {
      return state.stardust.post('/billing/create-checkout-session', payload);
    },

    StripeSessionStatus({ state, commit }, sessionId) {
      return state.stardust.get(`/billing/session-status/${sessionId}`)
        .then((response) => {
          commit('upsertStripeSessionStatus', response.data);
          return response.data;
        });
    },

    setStripeScript({ commit }) {
      return getStripeScript()
        .catch((err) => {
          commit(
            'global/replaceError',
            (err && err.response && err.response.data && err.response.data.error) || err,
            { root: true },
          );
          throw err;
        });
    },
  },
};
