<template lang="pug">
  b-modal(
    v-model="isErrorShow"
    header-class="modal-header-error"
    body-class="modal-body-error"
    footer-class="modal-footer-error"
    hide-header,
    centered,
    @hidden="closeModal")

    .head-section
      h3 Error

    .body-section
      | {{errorMessage}}

    div.w-100(slot="modal-footer")
      .button.button-modal-error.expanded(@click="closeModal()")
        | Close
</template>

<script>
import { BModal } from 'bootstrap-vue';

const Error = {
  name: 'error',

  computed: {
    isErrorShow: {
      get() {
        return this.$store.state.global.error.isErrorShow;
      },
      // noop
      set() {},
    },
    errorMessage() {
      return this.$store.state.global.error.errorMessage;
    },
  },

  methods: {
    closeModal() {
      this.$store.commit('global/closeError');
    },
  },

  components: {
    BModal,
  },
};

export default Error;
</script>

<style lang="scss" scoped>
  .head-section {
    margin-bottom: 15px;
  }

  .body-section {
    margin-bottom: 15px;

    &-last {
      margin-bottom: 0;
      font-size: 13px;
    }
  }

  .modal-body-error {
    padding-top: 0;
    overflow: hidden;
    overflow-y: scroll;
  }

  .modal-header-error {
    border: none;
  }

  .modal-footer-error {
    border: none;
  }

  .buton-modal-error {
    margin: 0;
  }
</style>
