export const ACTION_REGISTER_FOOTNOTE = 'footnote/register';
export const ACTION_REMOVE_FOOTNOTE = 'footnote/remove';
export const GETTER_UNIQUE_FOOTNOTES = 'footnote/uniqueFootnotes';

export const footnote = {
  namespaced: true,
  state: {
    footnotes: [],
  },
  getters: {
    uniqueFootnotes({ footnotes }) {
      return footnotes
        .filter((footnote, index, self) => self.indexOf(footnote) === index);
    },
  },
  mutations: {
    setFootnotes(state, footnotes) {
      state.footnotes = footnotes;
    },
  },
  actions: {
    register({ commit, state }, { text, priority }) {
      const footnotes = priority
        ? [
          text,
          ...state.footnotes,
        ]
        : [
          ...state.footnotes,
          text,
        ];

      commit('setFootnotes', footnotes);
    },
    remove({ commit, state }, footnote) {
      const index = state.footnotes.indexOf(footnote);

      if (index === -1) {
        return;
      }

      const footnotes = [...state.footnotes];
      footnotes.splice(index, 1);

      commit('setFootnotes', footnotes);
    },
  },
};
