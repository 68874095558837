// Used for footer copyright
// export enum companies {
//   'butlins' = 'Butlins',
//   'mediaforce'= 'Media Force',
//   'default'= 'wifinity',
// }

export const companies = {
  butlins: 'Butlins',
  fujitsu: 'Fujitsu',
  mediaforce: 'Media Force',
  parkconnect: 'Parkdean Resorts',
  optify: 'Optify',
  default: 'wifinity',
};
