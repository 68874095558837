export function setLocationIdForPasswordResetUrl(baseUrl, currentLocation) {
  let url = baseUrl;
  if (currentLocation.includes('/v2/#/password-reset')) {
    const paramsArray = currentLocation.substring(currentLocation.indexOf('?') + 1).split('&');
    const neededParam = paramsArray.find(element => element.includes('location_id='));
    if (neededParam) {
      url = `${baseUrl}?${neededParam}`;
    }
  }
  return url;
}
