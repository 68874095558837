import { Plan, PlansWOSingle } from '@/types/plan';
import { NewUser, User } from '@/types/user';

// NOTE: Meta and TikTok Pixel tracking use same event naming,
// we will track all TikTok event here
export enum MetaTrackingEvent {
  AddPaymentInfo = 'AddPaymentInfo',
  AddToCart = 'AddToCart',
  CompleteRegistration = 'CompleteRegistration',
  InitiateCheckout = 'InitiateCheckout',
  Lead = 'Lead',
  ViewContent = 'ViewContent',
  Purchase = 'Purchase', // CompletePayment // TikTok
}

export type MetaTrackingObject = {
  value: number;
  currency: string;
  contents: { id: string, quantity: number }[];
  'content_type': string;
};

export type TikTokTrackingObject = {
  value: number;
  currency: string;
  contents: {
    'content_id': string,
    quantity: number,
    price: number,
  }[];
  'content_type': string,
};

export function metaTrackCompleteRegistration(user: NewUser, plan?: Plan | PlansWOSingle) {
  // META
  if (window.fbq && user) {
    window.fbq('track', MetaTrackingEvent.CompleteRegistration, {
      content_name: 'Registration Form',
      content_category: 'Signup',
      content_ids: [user.userId],
      content_type: 'user_registration',
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
    });
  }

  // TikTok
  if (window.ttq && user && plan) {
    window.ttq.track(MetaTrackingEvent.CompleteRegistration, {
      value: plan.price,
      currency: plan.currency,
      contents: [{
        content_id: plan.id,
        quantity: 1,
        price: plan.price,
      }],
      content_type: 'product',
    });
  }
}

export function metaTrackEvent(sessionId: string, user: User, plan: Plan | PlansWOSingle, chargedAmount: number, eventName: MetaTrackingEvent) {
  // META
  if (window.fbq && sessionId && user && plan && chargedAmount && eventName) {
    let trackingObj: MetaTrackingObject | null = null;
    trackingObj = {
      value: chargedAmount,
      currency: plan.currency,
      contents: [{
        id: plan.id,
        quantity: 1,
      }],
      content_type: 'product',
    };
    if (trackingObj) window.fbq('track', eventName, trackingObj);
  } else if (window.fbq && eventName) {
    window.fbq('track', eventName);
  }

  // TikTok
  if (window.ttq && sessionId && user && plan && chargedAmount && eventName) {
    let trackingObj: TikTokTrackingObject | null = null;
    const trackEventName = eventName !== MetaTrackingEvent.Purchase ? eventName : 'CompletePayment';

    trackingObj = {
      value: chargedAmount,
      currency: plan.currency,
      contents: [{
        content_id: plan.id,
        quantity: 1,
        price: plan.price,
      }],
      content_type: 'product',
    };

    if (trackingObj) window.ttq.track(trackEventName, trackingObj);
  }
}
