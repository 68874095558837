export const SET_STARDUST_TOKEN = 'stardustSession/SetToken';
export const GET_STARDUST_TOKEN = 'stardustSession/GetToken';
export const REMOVE_STARDUST_TOKEN = 'stardustSession/RemoveToken';
export const stardustSession = {
  namespaced: true,
  actions: {
    SetToken(store, token) {
      localStorage.setItem('Token', token);
    },
    GetToken() {
      return localStorage.getItem('Token');
    },
    RemoveToken() {
      localStorage.removeItem('Token');
    },
  },
};
