/*
 * Adds css file in html head and class name on div #app
 */
export function addCssStyle(cssObj: {name: string}): void {
  if (cssObj && !cssObj.name) return;

  const body = document.querySelector('body');
  if (!body) return;

  body.classList.add(cssObj.name);
}
