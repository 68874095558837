export const ACTION_GET_PREFERENCES = 'marketing/getPreferences';
export const ACTION_GET_USER_PREFERENCES = 'marketing/getUserPreferences';
export const ACTION_UPDATE_USER_PREFERENCES = 'marketing/updateUserPreferences';

export const marketing = {
  namespaced: true,
  state: {
    preferences: [],
    userPreferences: [],
  },
  mutations: {
    upsertPreferences(state, preferences) {
      state.preferences = preferences;
    },
    upsertUserPreferences(state, userPreferences) {
      state.userPreferences = userPreferences;
    },
  },
  actions: {
    getPreferences({ commit, rootState }) {
      return rootState.api.stardust.get('/marketing-preferences')
        .then((response) => {
          commit('upsertPreferences', response.data);
          return response.data;
        });
    },
    getUserPreferences({ commit, rootState }, userId) {
      return rootState.api.stardust.get(`/marketing-preferences/${userId}`)
        .then((response) => {
          commit('upsertUserPreferences', response.data);
          return response.data;
        });
    },
    updateUserPreferences({ rootState }, { userId, userPreferences }) {
      return rootState.api.stardust.put(`/marketing-preferences/${userId}`, userPreferences);
    },
  },
};
