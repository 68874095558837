import {
  Module,
  MutationTree,
  ActionTree,
  ActionContext,
} from 'vuex';
import { SnackbarInitialState, RootState } from '@/types/store';
import { SnackbarType } from '@/models/enums/snackbar';

const defaultSnackbarOptions = {
  timeout: 5000,
  type: SnackbarType.Info,
};

export class SnackbarState implements SnackbarInitialState {
  isOpen = false;

  text = '';

  timeout = defaultSnackbarOptions.timeout;

  type = defaultSnackbarOptions.type;
}

export const enum SnackbarMutation {
  SetIsOpen = 'SetIsOpen',
  SetText = 'SetText',
  SetTimeout = 'SetTimeout',
  SetType = 'SetType',
}

const mutations: MutationTree<SnackbarInitialState> = {
  SetIsOpen(state: SnackbarInitialState, isOpen: boolean): void {
    state.isOpen = isOpen;
  },
  SetText(state: SnackbarInitialState, text: string): void {
    state.text = text;
  },
  SetTimeout(state: SnackbarInitialState, timeout: number): void {
    state.timeout = timeout;
  },
  SetType(state: SnackbarInitialState, type: SnackbarType): void {
    state.type = type;
  },
};

export const enum SnackbarAction {
  Close = 'snackbar/Close',
  Open = 'snackbar/Open',
}

export type SnackbarActionContext = ActionContext<SnackbarInitialState, RootState>;

type SnackbarOptions = {
  text: string,
  timeout?: number,
  type: SnackbarType,
};

const actions: ActionTree<SnackbarInitialState, RootState> = {
  Close({ commit }: SnackbarActionContext): void {
    commit(SnackbarMutation.SetIsOpen, false);
  },
  async Open({ commit, dispatch }: SnackbarActionContext, options: SnackbarOptions): Promise<void> {
    // wait for the previous notification to close so that the timeout resets properly
    await dispatch('Close');

    const { text, timeout, type } = {
      ...defaultSnackbarOptions,
      ...options,
    };

    commit(SnackbarMutation.SetText, text);
    commit(
      SnackbarMutation.SetTimeout,
      // in case of errors with no custom timeout, we want them to remain visible indefinitely
      type === SnackbarType.Error && !options.timeout
        ? 0
        : timeout,
    );
    commit(SnackbarMutation.SetType, type);
    commit(SnackbarMutation.SetIsOpen, true);
  },
};

export class SnackbarStore implements Module<SnackbarInitialState, RootState> {
  actions = actions;

  mutations = mutations;

  namespaced = true;

  state: SnackbarInitialState;

  constructor() {
    this.state = new SnackbarState();
  }
}
