import Vue from 'vue';

function beforeCreate() {
  const options = this.$options;

  if (options.bus) {
    this.$bus = options.bus;
  } else if (options.parent && options.parent.$bus) {
    this.$bus = options.parent.$bus;
  }
}

export const bus = new Vue();

export default {
  install(_Vue) {
    _Vue.mixin({
      beforeCreate,
    });
  },
};
