import _ from 'lodash';

export const stepManager = {
  namespaced: true,
  state: {
    step: 1,
    stardustSteps: ['sign-up', 'confirm-plan', 'pay'],
  },
  getters: {
    totalSteps(state, getters, rootState) {
      const totalSteps = state.stardustSteps.length;
      return rootState.plan.cost.isZeroCost ? totalSteps - 1 : totalSteps;
    },
    currentStep: (state, getters, rootState) => (name) => {
      const currentStep = _.indexOf(
        state.stardustSteps,
        name,
      ) + 1;
      return currentStep === 0 ? null : currentStep;
    },
  },
  mutations: {
    set(state, step) {
      state.step = step.step;
    },
    next(state) {
      state.step++;
    },
    prev(state) {
      state.step--;
    },
  },
  actions: {
    setStep({ commit }, step) {
      commit('stepManager/set', step, { root: true });
    },
  },
};
