import { VuetifyThemeVariant } from 'vuetify/types/services/theme.d';

const theme: Partial<VuetifyThemeVariant> = {
  primary: '#735CF5',
  secondary: '#05052D',
  accent: '#6ED48C',
  white: '#ffffff',
};

export default theme;
