
import {
  Module,
  MutationTree,
  ActionContext,
  ActionTree,
} from 'vuex';
import { RootState, ModalInitialState } from '@/types/store';
import { Modal } from '@/types/modal';

export const enum ModalMutation {
  SetIsVisible = 'SetIsVisible',
  SetMessage = 'SetMessage',
  SetModal = 'SetModal',
  ClearModal = 'ClearModal',
}

export const enum ModalAction {
  Open = 'modal/Open',
  Close = 'modal/Close',
}

export type ModalActionContext = ActionContext<ModalInitialState, RootState>;

export const modal = {
  namespaced: true,
  state: {
    isVisible: false,
    message: 'test message',
    action: null,
    actionLabel: '',
    showClose: true,
  },
  mutations: {
    SetIsVisible(state: ModalInitialState, isVisible: boolean): void {
      state.isVisible = isVisible;
    },
    SetMessage(state: ModalInitialState, message: string): void {
      state.message = message;
      state.isVisible = true;
    },
    SetModal(state: ModalInitialState, {
      message,
      action,
      actionLabel,
      showClose,
    }: Modal): void {
      state.message = message;
      state.action = action;
      state.actionLabel = actionLabel;
      state.showClose = showClose;
      state.isVisible = true;
    },
    ClearModal(state: ModalInitialState): void {
      state.isVisible = false;
      state.message = '';
      state.action = null;
      state.actionLabel = '';
      state.showClose = true;
    },
  },
  actions: {
    Close({ commit }: ModalActionContext): void {
      commit(ModalMutation.ClearModal);
    },
    Open({ commit }: ModalActionContext, options: Modal): void {
      if (typeof options === 'string') {
        commit('SetMessage', options);
      } else {
        commit('SetModal', options);
      }
    },
  },
};
