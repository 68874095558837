import store from '@/store';
import { companies } from '@/data/companyByCSSName';

export default {
  data() {
    return {
      msg: 'Hello World',
    };
  },
  computed: {
    // Returns selected custom css theme
    cssTheme(): {} {
      return store.state.siteInfo.siteCssInfoResponse;
    },
    isDefaultTheme(): boolean {
      return store.state.siteInfo.siteCssInfoResponse.name === 'default' || !store.state.siteInfo.siteCssInfoResponse.name;
    },
    isParkConnectTheme(): boolean {
      return store.state.siteInfo.siteCssInfoResponse.name === 'parkconnect';
    },
    isMediaForceTheme(): boolean {
      return store.state.siteInfo.siteCssInfoResponse.name === 'mediaforce';
    },
    isButlinsTheme(): boolean {
      return store.state.siteInfo.siteCssInfoResponse.name === 'butlins';
    },
    isOptifyTheme(): boolean {
      return store.state.siteInfo.siteCssInfoResponse.name === 'optify';
    },
    isNoUserJourneyEnabled(): string {
      return store.state.siteInfo.isNoUserJourneyEnabled;
    },
    companyName(): string {
      const name = store.state.siteInfo.siteCssInfoResponse!.name || 'default';
      return companies[name as keyof typeof companies] || 'Wifinity';
    },
    isEmailActivationEnabled(): boolean {
      return store.getters['siteInfo/isEmailActivationEnabled'];
    },
    isInternalPlanEngine(): boolean {
      return store.state.siteInfo.planEngine === 'INTERNAL';
    },
    isWifinityOneEnabled(): boolean {
      return store.state.siteInfo.isWifinityOneEnabled;
    },
    isSFA(): boolean {
      return store.getters['siteInfo/isSFA'];
    },
    isAnchor(): boolean {
      return store.getters['siteInfo/isAnchor'];
    },
    isAnchorNUJ(): boolean {
      return store.getters['siteInfo/isAnchorNUJ'];
    },
    isFujitsu(): boolean {
      return store.getters['siteInfo/isFujitsu'];
    },
    isWoodvale(): boolean {
      return store.getters['siteInfo/isWoodvale'];
    },
    isFreeSignUp(): boolean {
      return store.getters['siteInfo/isFreeSignUp'];
    },
    isNAAS(): boolean {
      return store.getters['siteInfo/isNAAS'];
    },
  },
};
