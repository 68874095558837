<template lang="pug">
  b-modal(
    v-model="isVisible"
    header-class="modal-header-error"
    body-class="modal-message-body"
    footer-class="modal-footer-error"
    :no-close-on-backdrop="!showClose"
    :no-close-on-esc="!showClose"
    hide-header
    @hidden="onHidden")

    .message
      h3 {{message}}

    .actions.s-flex.w-100(slot="modal-footer")
      ._button.secondary.large.w-100(
        v-if="showClose"
        @click="closeModal()"
      ) Close
      ._button.primary.large.w-100(
        v-if="action && label"
        @click="handleAction()"
      ) {{label}}
</template>

<script>
import { BModal } from 'bootstrap-vue';

const ModalMessage = {
  name: 'modal-message',

  computed: {
    isVisible: {
      get() {
        return this.$store.state.modal.isVisible;
      },
      set() {},
    },
    message() {
      return this.$store.state.modal.message;
    },
    label() {
      return this.$store.state.modal.actionLabel;
    },
    action() {
      return this.$store.state.modal.action;
    },
    showClose() {
      return this.$store.state.modal.showClose;
    },
  },

  methods: {
    onHidden() {
      this.$store.commit('modal/ClearModal');
    },
    closeModal() {
      this.$store.commit('modal/ClearModal');
    },
    handleAction() {
      this.action();
      this.closeModal();
    },
  },

  components: {
    BModal,
  },
};

export default ModalMessage;
</script>

<style lang="sass">
.modal-message-body
  padding: 14px 32px 8px

  .message
    margin: 20px 0 0
    font-size: 14px
    text-align: center

    h3
      margin: 0

  .actions
    display: flex
    justify-content: center
</style>
