export const billingInformation = {
  namespaced: true,
  state: {
    billingInfo: {},
    credits: [],
    subscriptions: [],
    currentSubscription: {},
    selectedInvoices: [],
    selectedSubscription: null,
  },
  mutations: {
    upsertBillingInfo(state, billingInfo) {
      state.billingInfo = billingInfo;
    },
    upsertCredits(state, credits) {
      state.credits = credits;
    },
    upsertSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    upsertSelectedInvoices(state, selectedInvoices) {
      selectedInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
      state.selectedInvoices = selectedInvoices;
    },
    upsertSelectedSubscription(state, selectedSubscription) {
      state.selectedSubscription = selectedSubscription;
    },
  },
  actions: {
    getBillingInfo({ commit, rootState }) {
      return rootState.api.stardust.get('/billing')
        .then((response) => {
          commit('upsertBillingInfo', response.data);

          return response.data;
        });
    },
    fetchCredits({ commit, rootState }) {
      return rootState.api.stardust.get('/billing/credit-notes')
        .then((response) => {
          commit('upsertCredits', response.data);

          return response.data;
        });
    },
    fetchSubscription({ commit, rootState }, id) {
      return rootState.api.stardust.get(`/billing/subscriptions/${id}`)
        .then((response) => {
          commit('upsertSelectedSubscription', response.data);
        });
    },
    fetchSubscriptions({ commit, rootState }) {
      return rootState.api.stardust.get('/billing/subscriptions')
        .then((response) => {
          commit('upsertSubscriptions', response.data);

          return response.data;
        });
    },
    fetchInvoicesForSubscription({ commit, rootState }, { id, offset, records }) {
      const params = new URLSearchParams();

      if (offset) {
        params.append('offset', offset);
      }

      if (records) {
        params.append('records', records);
      }

      return rootState.api.stardust.get(`/billing/subscriptions/${id}/invoices?${params.toString()}`)
        .then((response) => {
          commit('upsertSelectedInvoices', response.data);

          return response;
        });
    },
    executeInvoice({ commit, rootState }, { invoiceId }) {
      return rootState.api.stardust.post(`/billing/invoice/${invoiceId}/execute`)
        .then(response => response.data);
    },
    downloadInvoice({ commit, rootState }, { invoiceId }) {
      return rootState.api.stardust.get(`/invoice/${invoiceId}.pdf`, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${invoiceId}.pdf`;
          link.click();
        });
    },
  },
};
