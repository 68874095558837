export async function getStripeScript() {
  return new Promise((resolve, reject) => {
    const scriptEl = document.createElement('script');

    scriptEl.src = 'https://js.stripe.com/v3/';
    scriptEl.async = true;
    scriptEl.onload = () => resolve();
    scriptEl.onerror = () => reject(new Error('Failed to load Stripe script'));

    document.body.appendChild(scriptEl);
  });
}
