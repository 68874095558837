export function getIcon(paymentMethod?: any): string {
  const type = paymentMethod ? paymentMethod.type || paymentMethod.cardType : '';

  enum cardTypes {
    Visa = 'VISA',
    MasterCard = 'MASTERCARD',
    AmericanExpress = 'AMERICAN EXPRESS',
    PayPal = 'PAYPAL',
  }

  if (!type) return 'cUnknownCard';

  switch (type.toUpperCase()) {
    case cardTypes.Visa:
      return 'cVisa';
    case cardTypes.MasterCard:
      return 'cMasterCard';
    case cardTypes.AmericanExpress:
      return 'cAmericanExpress';
    case cardTypes.PayPal:
      return 'cPayPal';
  }

  return 'cUnknownCard';
}

// add new card names (e.g. ApplePay, GooglePay) if needed
export const paymentMethodCardInfo = function (paymentMethod?: any): string {
  if (paymentMethod.cardType === 'PAYPAL') {
    return 'PayPal';
  }
  return `card ending in ${paymentMethod.lastFour}`;
};
