import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import light from './wifinity-light';
import dark from './wifinity-dark';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light,
      dark,
    },
  },
});
