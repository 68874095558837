function parseJwt(key) {
  const token = localStorage.getItem('Token');
  if (token) {
    try {
      return JSON.parse(atob(token.split('.')[1]))[key];
    } catch (error) {
      console.error(`Can't decode JWT token. ${error}`);
      return null;
    }
  }
  return null;
}

export function customerCodeJWT() {
  return parseJwt('customer_code');
}

export function expiredJWT() {
  return parseJwt('exp');
}

export function isExpiredJWT() {
  if (!parseJwt('exp')) {
    return true;
  }
  const expiryTime = new Date(parseJwt('exp') * 1000);
  let compareToDate = new Date();
  compareToDate = new Date(compareToDate.getTime() + 5 * 1000);
  return compareToDate.getTime() > expiryTime.getTime();
}

export function locationIdJWT() {
  return parseJwt('location_id');
}

export function macJWT() {
  return parseJwt('mac');
}

export function nasIdJWT() {
  return parseJwt('nas_id');
}

export function connectionTypeJWT() {
  return parseJwt('connection_type');
}

export function userIdJWT() {
  return parseJwt('user_id');
}

export function isAnonymousJWT() {
  const scopes = parseJwt('scope');
  return !!(scopes && Array.isArray(scopes) && scopes[0] === 'anonymous');
}
