import moment from 'moment';

export default {
  slice(value, start, end) {
    if (!value) return;
    return value.slice(start, end);
  },
  moment(value, format) {
    if (!value) return;
    return moment(value).format(format);
  },
  fromNow(value) {
    if (!value) return;
    return moment(value).fromNow();
  },
  splitText(value) {
    if (!value) return;
    return value.replace(/([a-z])([A-Z])/g, '$1 $2');
  },
  kebabCase(value) {
    if (!value) return;
    return value.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
  },
  toCurrency(value) {
    if (!value && value !== 0) return;
    return value.toFixed(2);
  },
  uppercase(value) {
    if (!value) return;
    return value.toUpperCase();
  },
  featureToString(value) {
    const string = value.split(/(?=[A-Z])/).map((val) => {
      switch (val) {
        case 'Sd': return 'SD';
        case 'Hd': return 'HD';
        default: return val;
      }
    });
    return string.join(' ');
  },
  formatSpeed(mbps) {
    return mbps >= 1000
      ? `${mbps / 1000} Gbps`
      : `${mbps} Mbps`;
  },
};
