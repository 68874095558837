import { MUTATION_UPSERT_PAYMENT_METHOD } from '@/store/dashboard';

import { RESET_PASSWORD_COUNTER_TIME } from '@/constants/baseConstants';

export const ACTION_DELETE_DEFAULT_METHOD = 'account/deleteDefaultMethod';
export const ACTION_FETCH_ACCOUNT = 'account/fetchAccount';

export const account = {
  namespaced: true,
  state: {
    account: {},
    resetPasswordCounter: RESET_PASSWORD_COUNTER_TIME,
  },
  mutations: {
    upsertAccount(state, newAccount) {
      state.account = newAccount;
    },
  },
  actions: {
    fetchAccount({ commit, rootState }) {
      return rootState.api.stardust.get('/user')
        .then((response) => {
          commit('upsertAccount', response.data);

          return response.data;
        });
    },
    editAccount({ commit, dispatch, rootState }, accountData) {
      return rootState.api.stardust.patch('/user', accountData)
        .then((response) => {
          commit('upsertAccount', response.data);
          dispatch('siteInfo/fetchSiteInfo', null, { root: true });

          return response.data;
        }).catch(e => Promise.reject(e));
    },
    editDetailedAgreements({ commit, dispatch, rootState }, accountData) {
      return rootState.api.stardust.patch('/user/agreements', accountData)
        .then((response) => {
          commit('upsertAccount', response.data);
          dispatch('siteInfo/fetchSiteInfo', null, { root: true });
          return response.data;
        }).catch(e => Promise.reject(e));
    },
    updateUserType({ dispatch, rootState }, { id, userType }) {
      return rootState.api.stardust.patch(`/user/${id}/type`, `${userType}`, { headers: { 'Content-Type': 'application/json' } })
        .then((response) => {
          dispatch('siteInfo/fetchSiteInfo', null, { root: true });
          return response.data;
        }).catch(e => Promise.reject(e));
    },
    deleteDefaultMethod({ commit, rootState }) {
      return rootState.api.stardust.delete('/payment-method')
        .then((response) => {
          // Clear default payment method
          commit(MUTATION_UPSERT_PAYMENT_METHOD, null, { root: true });

          return response.data;
        })
        .catch(e => Promise.reject(e));
    },
    updatePassword({ rootState }, { newPassword, existingPassword }) {
      return rootState.api.stardust.post('/user/change-password', {
        newPassword,
        existingPassword,
      })
        .then(response => response.data)
        .catch(e => Promise.reject(e));
    },
    requestPasswordResetSms({ rootState }, data) {
      return rootState.api.stardust.post('/user/password-reset/request-sms', {
        email: data.email,
        phoneNumber: data.phone,
      })
        .catch(e => Promise.reject(e));
    },
    changePasswordBySmsCode({ rootState }, data) {
      return rootState.api.stardust.post('/user/password-reset/by-sms', {
        code: data.code,
        password: data.password,
        email: data.email,
      })
        .catch(e => Promise.reject(e));
    },
  },
};
