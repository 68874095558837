import Vue from 'vue';
import Vuex from 'vuex';

// Stardust
import { plan } from '@/store/plan';
import { api } from '@/store/api';
import { account } from '@/store/account';
import { siteInfo } from '@/store/siteInfo';
import { globalStore as global } from '@/store/global';
import { ui } from '@/store/ui';
import { dashboard } from '@/store/dashboard';
import { deviceManagement } from '@/store/deviceManagement';
import { stepManager } from '@/store/stepManager';
import { upgradePlanManagement } from '@/store/upgradePlanManagement';
import { billingInformation } from '@/store/billingInformation';
import { footnote } from '@/store/footnote';
import { marketing } from '@/store/marketing';
import { modal } from '@/store/modal';
import { SnackbarStore } from '@/store/snackbar';
import { stardustSession } from '@/store/stardustSession';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    plan,
    api,
    account,
    siteInfo,
    global,
    ui,
    deviceManagement,
    dashboard,
    stepManager,
    upgradePlanManagement,
    billingInformation,
    footnote,
    marketing,
    modal,
    snackbar: new SnackbarStore(),
    stardustSession,
  },
});

export default store;
