export const themes = {
  butlins: {
    primary: '#da291c',
    secondary: '#05052D',
    accent: '#6ED48C',
  },
  mediaforce: {
    primary: '#1B4261',
    secondary: '#77a5af',
    accent: '#ee3224',
    purple: '#735CF5',
  },
  parkconnect: {
    primary: '#265AA2',
    secondary: '#d32859',
    accent: '#2B6145',
  },
  optify: {
    primary: '#68ACC2',
    secondary: '#164383',
    accent: '#164383',
  },
  sfa: {
    primary: '#B82B35',
    secondary: '#092365',
    accent: '#04062C',
  },
  anchor: {
    primary: '#252262',
    secondary: '#327079',
    accent: '#DB682F',
  },
  anchor_nuj: {
    primary: '#252262',
    secondary: '#327079',
    accent: '#DB682F',
  },
};
